<template>
  <router-view />
</template>
<script>
export default {
  watch: {
    $route() {
      document.title = (this.$route.meta && this.$route.meta.title) || ''
    },
  },
}
</script>

<style lang="less">
body {
  background: #f1f1f1;
}

.van-button--primary {
  background-color: #010101 !important;
  border: 1px solid #010101 !important;
}
.van-card {
  background-color: #fff !important;
}
.van-cell__value {
  font-weight: 600;
  color: #010101 !important;
}
.tip-box {
  margin-left: 20px;
  padding: 10px;
  color: #f00;
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 0;
  max-width: calc(50% - 560px);
  border: 2px solid #f00;
  box-sizing: border-box;
}
@media screen and (max-width: 720px) {
  .tip-box {
    top: 5px;
    max-width: 100%;
    font-size: 18px;
  }
}
</style>

<template>
  <div>
    <van-nav-bar
      v-if="isMobile"
      :title="title"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
    <router-view />
  </div>
</template>

<script>
import { isMobile } from '@/utils'
export default {
  name: 'Layout',
  computed: {
    title() {
      return this.$route.meta && this.$route.meta.title
    },
  },

  data() {
    return {
      isMobile,
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar /deep/ .van-icon {
  color: #010101;
}
.container {
  margin-top: 46px;
}
</style>

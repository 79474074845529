import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    form: {},
  },
  mutations: {
    SET_FORM: (state, form) => {
      state.form = form
    },
  },
  actions: {
    setForm({ commit }, form) {
      commit('SET_FORM', form)
    },
  },
  modules: {},
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: { title: 'Order confirmation' },
    component: () => import('../views/index.vue'),
  },
  {
    path: '/result',
    meta: { title: 'Payment Result' },
    component: () => import('../views/result.vue'),
  },
  {
    path: '/declare',
    meta: { title: 'Declare' },
    component: () => import('../views/declare.vue'),
  },
  {
    path: '/success',
    meta: { title: 'Payment Successful!' },
    component: () => import('../views/success.vue'),
  },
  {
    path: '/pending',
    meta: { title: 'Payment Pending!' },
    component: () => import('../views/pending.vue'),
  },
  {
    path: '/cancel',
    meta: { title: 'Payment Cancel!' },
    component: () => import('../views/cancel.vue'),
  },
  {
    path: '/fail',
    meta: { title: 'Payment Failed!' },
    component: () => import('../views/fail.vue'),
  },
  {
    path: '/dummytools',
    meta: { title: '' },
    component: Layout,
    children: [
      {
        path: '',
        meta: { title: 'Order confirmation' },
        component: () => import('../views/dummytools/index.vue'),
      },
      {
        path: 'result',
        meta: { title: 'Payment Result' },
        component: () => import('../views/dummytools/result.vue'),
      },
      {
        path: 'm',
        meta: { title: '' },
        component: Layout,
        children: [
          {
            path: '',
            meta: { title: 'Order confirmation' },
            component: () => import('../views/dummytools/mobile/index.vue'),
            name: 'OrderConfirmation',
          },
          {
            path: 'result',
            meta: { title: 'Payment Result' },
            component: () => import('../views/dummytools/mobile/result.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/m',
    meta: { title: '' },
    component: Layout,
    children: [
      {
        path: '',
        meta: { title: 'Order confirmation' },
        component: () => import('../views/mobile/checkout.vue'),
        name: 'OrderConfirmation',
      },
      {
        path: 'result',
        meta: { title: 'Payment Result' },
        component: () => import('../views/mobile/result.vue'),
        name: 'PaymentResult',
      },
      {
        path: 'declare',
        meta: { title: 'Declare' },
        component: () => import('../views/mobile/declare.vue'),
      },
      {
        path: 'success',
        meta: { title: 'Payment Successful!' },
        component: () => import('../views/mobile/success.vue'),
        name: 'PaymentSccess',
      },
      {
        path: 'fail',
        meta: { title: 'Payment Failed!' },
        component: () => import('../views/mobile/fail.vue'),
        name: 'PaymentSccess',
      },
      {
        path: 'pending',
        meta: { title: 'Payment Pending!' },
        component: () => import('../views/mobile/pending.vue'),
      },
      {
        path: 'cancel',
        meta: { title: 'Payment Cancel!' },
        component: () => import('../views/mobile/cancel.vue'),
      },
    ],
  },
  {
    path: '/fep',
    meta: { title: '' },
    component: Layout,
    children: [
      {
        path: '',
        meta: { title: 'Order confirmation' },
        component: () => import('../views/fep/index.vue'),
      },
      {
        path: 'result',
        meta: { title: 'Payment Result' },
        component: () => import('../views/fep/result.vue'),
      },
      {
        path: 'm',
        meta: { title: '' },
        component: Layout,
        children: [
          {
            path: '',
            meta: { title: 'Order confirmation' },
            component: () => import('../views/fep/mobile/index.vue'),
            name: 'OrderConfirmation',
          },
          {
            path: 'result',
            meta: { title: 'Payment Result' },
            component: () => import('../views/fep/mobile/result.vue'),
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

export default router
